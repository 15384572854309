import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import UseAuth from '../../hooks/UseAuth'
import * as api from '../../services/api_service'
import blogging from '../../assets/image/illus/blogging.gif'
import AppHelpers from '../../hooks/AppHook';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

function TradeHistory() {

  const { isAuthenticated } = UseAuth()
  const navigate = useNavigate()
  const { currencyFormat, ucfirst, theme } = AppHelpers()
  const [trades, setTrades] = useState([])
  const [show, setShow] = useState(false)
  const [isFetching, setIsFetching] = useState(true)
  // const [isFetchingTrade, setIsFetchingTrade] = useState(false)

  useEffect(() => {
    async function getTrades() {
      try {
        const resp = await api.trades()
        setTrades(resp.data.data)
        setIsFetching(false)
      } catch (error) {
        console.log(error)
        setIsFetching(false)
      }
    }

    getTrades()
  }, [])

  // async function getTrader() {
  //   setIsFetchingTrade(true)
  //   try {
  //     const resp = await api.trader(traderInput)
  //     navigate(`/user/copy-trader/${resp.data.data.ref}`)
  //   } catch (error) {
  //     console.log(error)
  //     toast.error(error.response.data.message, { ...toastConfig, autoClose: 8000 })
  //     setIsFetchingTrade(false)
  //   }
  // }

  const toastConfig = {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark"
  }

  return (
    <div className="col-12 col-lg-8">

      <div className="row">


        <div className="col-lg-12">
          <div className={classNames("card border-gray-300 p-0  mb-4 ", {
            "bg-dark-card": theme === "dark"
          })} >
            <div className="card-body" style={{ marginBottom: '-10px' }}>
              <h3 className={classNames("h5 mb-3", {
                        "text-dark-white": theme === "dark"
                      })} style={{ 'borderBottom': 'solid 3px #000', 'padding': '0px 10px 10px 5px', 'display': 'inline-block' }}>Trade History</h3>

            </div>
          </div>

          <div className="row" style={{ 'maxHeight': '750px', 'overflowY': 'auto' }}>
            {
              trades?.length > 0 ?
                trades.map((trade, k) => (
                  <div className="col-md-6" key={k}>
                    <div className={classNames("card border-gray-300 p-0  mb-4 ", {
                      "bg-dark-card": theme === "dark"
                    })} style={{ 'cursor': 'pointer' }} >
                      <div className={classNames("card-body", {
                        "text-dark-white": theme === "dark"
                      })}>
                        <div style={{ "display": "flex", "justifyContent": "space-between" }}>
                          <div>
                            <div><b><i className='fa fa-circle' style={{ 'fontSize': '11px', 'color': trade.status === 1 ? 'orange' : trade.status === 0 ? 'red' : 'green' }}></i> &nbsp;
                              {trade.status === 1 ? 'Running' : trade.status === 0 ? 'Lost' : 'Won'}</b></div>
                            <div className='mt-2'>{trade.symbol} </div>
                            <div className='mb-3'><small>{trade.created_at}</small></div>
                          </div>
                          <div>
                            <div style={{ padding: "20px" }}>
                              <div style={{ "padding": "5px 10px", "backgroundColor": "#eee", "color": "#1c2540 !important", "borderRadius": "10px", "fontSize": "13px" }}>
                                <b>{ucfirst(trade.type)} trade</b>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="row mb-2" >
                            <div className="col-4" style={{ 'fontSize': '13px' }}>
                              <span>Amount:</span>
                              <h5 className={classNames("h6 fw-bold mb-1", {
                                "text-dark-main": theme === "dark"
                              })}>{isAuthenticated ? currencyFormat(trade.amount) : <Skeleton width={150} />}</h5>
                            </div>
                            <div className="col-4" style={{ 'fontSize': '13px' }}>
                              <span>TP %:</span>
                              <h5 className={classNames("h6 fw-bold mb-1", {
                                "text-dark-main": theme === "dark"
                              })}>{isAuthenticated ? trade.tp : <Skeleton width={150} />}</h5>
                            </div>
                            <div className="col-4" style={{ 'fontSize': '13px' }}>
                              <span>Duration:</span>
                              <h5 className={classNames("h6 fw-bold mb-1", {
                                "text-dark-main": theme === "dark"
                              })}>{isAuthenticated ? trade.duration : <Skeleton width={150} />}</h5>
                            </div>
                          </div>
                          <div className="row mb-2" >
                            <div className="col-4" style={{ 'fontSize': '13px' }}>
                              <span>Order Type:</span>
                              <h5 className={classNames("h6 fw-bold mb-1", {
                                "text-dark-main": theme === "dark"
                              })}>{isAuthenticated ? ucfirst(trade.order_type) : <Skeleton width={150} />}</h5>
                            </div>
                            <div className="col-4" style={{ 'fontSize': '13px' }}>
                              <span>Entry:</span>
                              <h5 className={classNames("h6 fw-bold mb-1", {
                                "text-dark-main": theme === "dark"
                              })}>{isAuthenticated ? trade?.entry || '-- --' : <Skeleton width={150} />}</h5>
                            </div>
                            <div className="col-4" style={{ 'fontSize': '13px' }}>
                              <span>Direction:</span>
                              <h5 className={classNames("h6 fw-bold mb-1", {
                                "text-dark-main": theme === "dark"
                              })}>{isAuthenticated ? ucfirst(trade.direction) : <Skeleton width={150} />}</h5>
                            </div>
                          </div>
                          <span>Total Return:</span>
                          <h5 className={classNames("h4 fw-bold mb-1", {
                            "text-dark-main": theme === "dark"
                          })}>{isAuthenticated ?
                            trade.status === 1 ? '-- --' : trade.status === 0 ? <span className='text-danger'>$0.00</span> : <span className='text-success'>{currencyFormat(trade.amount + (trade.amount * (trade.tp / 100)))}</span>
                            : <Skeleton width={150} />}</h5>
                          <div className="small mt-2 text-success">
                            <span className="fas fa-angle-up "></span>
                            {/* <span className=" fw-bold">({(((trade.amount - trade.loss) / trade.amount) * 100).toFixed(2)}%)</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )) : isFetching ? <><div>Fetching ...</div></> :
                  <>
                    No Trades Found
                  </>
            }
          </div>
        </div>
      </div>

    </div>
  )
}

export default TradeHistory